<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>

      <data-view-sidebar :promptName="promptName" :isSidebarActive="displayPrompt" @closeSidebar="toggleDataSidebar" :formData="formData" />

        <vs-table ref="table" pagination :max-items="this.$store.state.pps" search :data="benefits">
            
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

              <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">

                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon">
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </button>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Delete</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Archive</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Another Action</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ADD NEW -->
                <div class="dd-actions cursor-pointer mr-4 mb-4" style="margin-bottom: 0px !important">
                  <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="addDataPrompt()">
                    <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                      <span class="ml-2 text-base text-primary">Add New</span>
                  </button>
                </div>
                <!-- REFRESH -->
                <button class="vs-component vs-button vs-button-primary vs-button-border includeIcon" @click="refreshData()">
                  <feather-icon icon="RefreshCwIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Refresh</span>
                </button>
              </div>
            </div>

            <template slot="thead">
                <vs-th class="add_th" sort-key="event_id">S.NO</vs-th>
                <vs-th class="add_th" sort-key="name">Benefit Name</vs-th>
                <vs-th class="add_th" sort-key="document_url">Document</vs-th>
                <vs-th class="add_th" sort-key="created_at">Date</vs-th>
                <vs-th class="add_th" sort-key="status">Status</vs-th>
                <vs-th class="add_th">Action</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td :data="data[indextr].benefit_id">
                        {{indextr+1}}
                    </vs-td>
                    <vs-td :data="data[indextr].name">
                        {{data[indextr].name}}
                    </vs-td>

                   
                   
                    <vs-td :data="data[indextr].document_url">
                        <a :href="data[indextr].document_url" target="_blank">doc</a>
                    </vs-td>

                    <vs-td :data="data[indextr].created_at">
                        {{date_format(data[indextr].created_at)}}
                    </vs-td>

                    <vs-td :data="data[indextr].status">
                        <vs-chip v-if="data[indextr].status==0" :color="'#d9534f'" class="product-order-status">InActive</vs-chip>
                        <vs-chip v-if="data[indextr].status==1" :color="'#5cb85c'" class="active_btns product-order-status">Active</vs-chip>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap" v-if="org_id == data[indextr].org_id">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="editDataPrompt(tr)" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="confirmDeleteRecord(tr.benefit_id,data[indextr].name)" />
                    </vs-td>
                     <vs-td class="whitespace-no-wrap" v-if="org_id !== data[indextr].org_id">
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current edit_delete" @click.stop="disableDeleteRecord()" />
                      <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current edit_delete" class="ml-2" @click="disableDeleteRecord()" />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>

        <!-- <vs-prompt
            :title="promptName"
            :accept-text= "promptName"
            button-cancel = "border"
            @accept="addEditBenefit"
            :is-valid="validateForm"
            :active.sync="displayPrompt"
            >
            <div>
                <form>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-input 
                              v-model="formData['name']"
                              label="Benefit Name"
                              v-validate="'required'" 
                              name="name" 
                              class="w-full mb-4 mt-5 required" 
                              placeholder="Benefit Name" />
                          
                            <div class="mt-4">
                              <label class="text-sm" style="margin-left: 4px;">Upload Document  <span class="text-danger">*</span></label><br>
                              <input type="file" ref="du" class="hidden" @change="upload_document" accept=".doc,.pdf,.docx">
                              <vs-button size="small" type="border" @click="$refs.du.click()">
                                <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                                <span class="ml-2 text-base text-primary">Upload</span>
                              </vs-button>
                            </div>

                            <div class="mt-4">
                              <label class="text-sm" style="margin-left: 4px;">Uploaded Document Url</label><br>
                              <vs-chip class="uploaded_media_url">{{formData['document_url']}}</vs-chip>
                            </div><br>
                            <div class="mt-8">
                              <vs-switch color="success" v-model="status_model">
                                  <span slot="on">Active</span>
                                  <span slot="off">InActive</span>
                              </vs-switch>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </vs-prompt> -->

    </vx-card>
</template>

<style>
.file_container{
  position: relative;
  height: 100px;
  width: 200px;

}
input[type='file']{
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  border:1px dashed rgba(0,0,0,.1);
  /*border-radius:8px;*/
  /*padding: 0 0 0 200px;*/

}
.fb{
  position: absolute;
  bottom: 55%;left: 45%;
}

</style>

<script>
// import moduleBenefit          from '@/store/benefit/moduleBenefit.js'
import DataViewSidebar from './DataViewSidebar.vue'
import vSelect from 'vue-select'
import axios from '@/axios.js'

export default {
  data () {
    return {
      promptName           : 'Add New Benefit',
      formData             : {},
      displayPrompt        : false,
      'org_id':this.$store.state.AppActiveUser.org_id
    }
  },
  components: {
    vSelect,
    DataViewSidebar
  },
  computed: {
    benefits ()     { 
      return this.$store.getters['benefit/queriedBenefits']      
    },
   
    upload_url() {
      return axios.defaults.baseURL+'/imageupload'
    },
    status_model: {
      get () {
        return this.formData['status']=='1'?true:false
      },
      set (status) {
        this.formData['status'] = status?'1':'0'
      }
    },
    validateForm () {
      return !this.errors.any() && this.formData.name!="" && this.formData.document_url!=""
    }
  },
  methods: {
    date_format (date) {
      const d = new Date(date)
      return d.toDateString()
    },
    toggleDataSidebar (val = false) {
      this.displayPrompt = val
    },
    refreshData () {
      this.$vs.loading()
      this.$store.dispatch('benefit/fetchBenefits').then(() => { this.$vs.loading.close() })
    },
    addDataPrompt () {
      this.formData = {"name":"","document_url":"","image_url":"","org_id":this.$store.state.AppActiveUser.org_id,"status":false}
      this.promptName = "ADD NEW BENEFIT"
      this.toggleDataSidebar(true)
      localStorage.setItem('update_benefit_image',0)
      localStorage.setItem('update_benefit_document',0)
    },
    editDataPrompt (benefit) {
      this.promptName = "UPDATE BENEFIT"
      this.toggleDataSidebar(true)
      this.formData = Object.assign({}, benefit)
      localStorage.setItem('update_benefit_image',1)
      localStorage.setItem('update_benefit_document',1)
    },
    upload_document (e) {
      this.$vs.loading()
      this.$store.dispatch('benefit/uploadDocument', {doc:e.target.files[0], benefit_id:this.formData.benefit_id}).then((res)=>{
        this.formData['document_url'] = res
         this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
    },
    notif (error) {
      this.$vs.notify({
        title: 'Removed',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
     confirmDeleteRecord (id,name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You Are About To Delete Benefit Name Of "${name}"`,
        accept: this.deleteBenefit,
        acceptText: 'Confirm',
        parameters: id
      })
    },
    disableDeleteRecord(){
       this.$vs.notify({
        title: 'Alert',
        text: "Permission Deined..!!",
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })    
    },
    deleteBenefit (parameters) {
      this.$vs.loading()
      this.$store.dispatch('benefit/deleteBenefit', parameters).then(() => { 
                  localStorage.removeItem('benefit_delete_id')
                  this.$store.dispatch('benefit/fetchBenefits')
                  this.$vs.loading.close()
                  const error = "Benefit Removed Successfully...!!!"
                  this.notif(error)     
       }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
            })
    },
  },
  created () {
    // this.$store.registerModule('benefit', moduleBenefit)
    if(this.benefits.length==0){
      this.$vs.loading()
      this.$store.dispatch('benefit/fetchBenefits').then(() => { this.$vs.loading.close() })
    }
  },
  beforeDestroy() {
    // this.$store.unregisterModule('benefit')
  }
}
</script>
 