<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ promptName }}</h4>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Product Image -->
        <template>

          <!-- Image Container -->
          <div class="img-container w-full mx-auto flex items-center justify-center" >
            <img :src="formData['image_url']||'/600x400.png'" alt="img" class="responsive" style="height: 168px;">
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5 remove_image"  v-if="remove_image">
            <input type="file" class="hidden" ref="updateImgInput" @change="remove_images" accept=".png,.jpg,.jpeg">
            <vs-button type="border" color="danger"  v-if="remove_image" @click="$refs.updateImgInput.click()">Replace Image</vs-button>
          </div>

          <div class="modify-img flex justify-between mt-5 remove_image"  v-if="add_image">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept=".png,.jpg,.jpeg">
            <vs-button type="border" v-if="add_image" @click="$refs.updateImgInput.click()">Update Image</vs-button>
          </div>
        </template>

        <vs-input 
          v-model="formData['name']"
          v-validate="'required'" 
          name="name" 
          label="Benefit Name"
          class="w-full mb-4 mt-5 required" 
          placeholder="Benefit Name" />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>

        <div class="mt-4"  v-if="upload_medias">
            <label class="text-sm" style="margin-left: 4px;">Upload Document  <span class="text-danger">*</span></label><br>
            <input type="file" ref="du" class="hidden" @change="upload_document" accept=".pdf">
            <vs-button size="small" type="border"  @click="$refs.du.click()">
            <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Upload</span>
          </vs-button>
        </div>

          <div class="mt-4" v-if="remove_medias">
            <label class="text-sm" style="margin-left: 4px; color: red;">Replace Document</label><br>
              <input type="file" ref="du" class="hidden"   @change="remove_media" accept=".pdf">
              <vs-button size="small" type="border" color="danger" @click="$refs.du.click()">
              <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary" style="color:red !important;">Replace</span>
            </vs-button>
          </div>
        <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Uploaded Document Url</label><br>
          <vs-chip class="uploaded_media_url">{{formData['document_url']}}</vs-chip>
        </div><br>
          <div class="mt-4">
          <label class="text-sm" style="margin-left: 4px;">Short Description <span class="text-danger">*</span></label>
            <vs-textarea rows="4" v-validate="'required'" maxlength="240" name="long_desc" v-model="formData['long_desc']" placeholder="Short Description..." />
            <span class="text-danger text-sm" v-show="errors.has('long_desc')">{{ errors.first('long_desc') }}</span>
          </div>
        <div class="mt-8">
          <label class="text-sm" style="margin-left: 4px;">Status</label><br>
          <vs-switch color="success" v-model="status_model" style="width: 70px !important;">
              <span slot="on">Active</span>
              <span slot="off">InActive</span>
          </vs-switch>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" :disabled="!isCancel"  @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    promptName: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      remove_medias:'',
     upload_medias:true,
      add_image: true,
       remove_image : '',
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
            this.add_image = true;
        this.formData['image_url'] = '/600x400.png' 
        this.remove_image = false;
        this.formData['document_url'] = ''
        this.upload_medias = true;
        this.remove_medias = false;
          this.$emit('closeSidebar')
          // this.$validator.reset()
        }
      }
    },
    status_model: {
      get () {
        return this.formData['status'] === '1'
      },
      set (status) {
        this.formData['status'] = status ? '1' : '0'
      }
    },
      isCancel (){
        const update_benefit_image = localStorage.getItem('update_benefit_image')
        const update_benefit_document = localStorage.getItem('update_benefit_document')
      if(update_benefit_image == 1 || update_benefit_document == 1){
       return !this.errors.any() && this.formData['document_url'] !== '' && this.formData['image_url'] !== '' && this.formData['image_url'] && this.formData['document_url']
      }else{
        return true
      }

    },
    isFormValid () {
        const update_benefit_image = localStorage.getItem('update_benefit_image')
        const update_benefit_document = localStorage.getItem('update_benefit_document')

        if(update_benefit_image == 1){
            this.remove_image = true;
            this.add_image = false;
        }else{
            this.add_image = true;
            this.remove_image = false;
        }
        if(update_benefit_document == 1){
            this.upload_medias = false;
            this.remove_medias = true;
        }else{
            this.upload_medias = true;
            this.remove_medias = false;
        }
      return !this.errors.any() && this.formData['long_desc'] !== '' && this.formData['name'] !== '' && this.formData['document_url'] !== '' && this.formData['image_url'] !== '' && this.formData['status'] !== '' && this.formData['org_id'] !== '' && this.formData['name'] && this.formData['document_url'] && this.formData['image_url'] && this.formData['org_id']  && this.formData['long_desc']
    },
    scrollbarTag () { 
      if (this.promptName == 'UPDATE BENEFIT') {
        this.upload_medias = false;
        this.remove_medias = true;
         this.remove_image = false;
        this.add_image = true;
     }else{
        this.upload_medias = true;
        this.remove_medias = false;
         this.add_image = true;
        this.remove_image = false;
     }
      return this.$store.getters.scrollbarTag }
  },
  methods: {
  
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          if (this.promptName === 'UPDATE BENEFIT') {
            this.$validator.validateAll().then(result => {
              if (result) {
                this.$store.dispatch('benefit/updateBenefit', Object.assign({}, this.formData)).then((res) => {
                  this.$vs.loading.close()  
                  const success = 'Benefit Updated Successfully...!!!'
                  this.notif_sucess(success) 
              }).catch((error) => { 
                  this.$vs.loading.close()  
                  this.notif(error)
                })
              }
            })
          } else {
            this.$validator.validateAll().then(result => {
              if (result) {
                this.$store.dispatch('benefit/addBenefit', Object.assign({}, this.formData)).then((res) => {
                  this.$vs.loading.close()  
                  const success = 'Benefit Added Successfully...!!!'
                  this.notif_sucess(success) 
              }).catch((error) => {
                  if( error.message == "Request failed with status code 423"){
                          error.message = 'Benefit is created by same name..!!!'
                      }else{
                          error = error.message
                      } 
                  this.upload_medias = true;
                  this.remove_medias = false;
                  this.add_image = true;
                  this.remove_image = false;
                  this.$vs.loading.close()  
                  this.notif(error)     
                })
              }
            })
          }
          this.$emit('closeSidebar')
        }
      })
    },
     notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
     notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
      upload_document (e) {
      let file = e.target.files[0];
      const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
     if(sizeInMB <= 25){
       this.$vs.loading()
      this.$store.dispatch('benefit/uploadDocument', {doc:e.target.files[0], benefit_id:this.formData.benefit_id}).then((res) => {
        localStorage.setItem('update_benefit_document',1)
        this.formData['document_url'] = res
        this.upload_document_url = 1
        this.upload_medias =false,
        this.$refs.du.value = '',
       this.remove_medias = true,
        this.$vs.loading.close()  
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
      }else{
        this.$vs.loading.close() 
        this.$refs.du.value = ''
        const error = "Maximum we can upload 25Mb size of documents..!!"
        this.notif_failed(error)
      }
    },
    remove_media (e) {
         let file = e.target.files[0];
      const fileSize = file.size
      var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
     if(sizeInMB <= 25){
              this.$vs.loading()
              this.$vs.loading()
            this.$store.dispatch('benefit/removeDocument', {doc:e.target.files[0], benefit_id:this.formData.benefit_id,oldDoc:this.formData['document_url'], media_id:this.formData.media_id}).then((res) => {
               localStorage.setItem('update_benefit_document',1)
                this.formData['document_url'] = res
                this.upload_document_url = 1
                this.upload_medias =false,
                this.$refs.du.value = '',
              this.remove_medias = true,
                this.$vs.loading.close()  
              }).catch((error) => { 
                          this.$vs.loading.close()
                          this.notif(error)     
                })
      }else{
        this.$vs.loading.close() 
        this.$refs.du.value = ''
        const error = "Maximum we can upload 25Mb size of documents..!!"
        this.notif_failed(error)
      }
    }, 
     notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
     updateCurrImg (e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                            this.errImageupload = 1
                            this.$refs.updateImgInput.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$vs.loading()
      this.$store.dispatch('benefit/uploadImage', {image:e.target.files[0], benefit_id:this.formData['benefit_id']}).then((res) => {
          this.formData['image_url'] = res
          this.$refs.updateImgInput.value = ''
          this.add_image = false;
          this.remove_image = true; 
           localStorage.setItem('update_benefit_image',1)
           this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
                })
      }
    }, 200);
  },
   
    remove_images(e) {
       let file = e.target.files[0];
                let reader = new FileReader();
                  const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 400 || img.width !== 600) || (sizeInMB > 2)){
                            this.errImageupload = 1
                            this.$refs.updateImgInput.value = ''
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 600*400 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$vs.loading()
      this.$store.dispatch('benefit/removeImage', {image:e.target.files[0], benefit_id:this.formData['benefit_id'],oldImage:this.formData['image_url']}).then((res)=>{
       this.formData['image_url'] = res
       this.$refs.updateImgInput.value = ''
          this.add_image = false;
          this.remove_image = true; 
           localStorage.setItem('update_benefit_image',1)
           this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
         }
    }, 200);
    },
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
